import React from 'react';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import NavigationBar from '../genericComponents/NavigationBar';

import ContentBox from '../genericComponents/ContentBox';
import CollapableBoxList from '../genericComponents/CollapsableContentBoxList';
import Footer from '../genericComponents/Footer';
import mountainTree from '../images/mountainTree.jpg';

const Therapy = () => (
  <>
    <NavigationBar />
    <Box minHeight="calc(100vh - 86px - 107px - 42px)">
    <Container fixed>
      <Grid container spacing={3} id="recordo">
        <ContentBox src={mountainTree} imageWidth={4} direction="right">
          <Typography variant="h4" color="textPrimary" gutterBottom paragraph>
            E.E.N.® Therapie
          </Typography>
          <Typography color="textPrimary" paragraph>
            De E.E.N.® Therapie staat voor een holistische manier van behandelen.
            Vaak is er een oorzakelijk verband tussen fysiek klachten en de
            emotionele of mentale communicatie-systemen.
          </Typography>
          <Typography color="textPrimary" paragraph gutterBottom>
            Het afvoeren van toxische belastingen in al deze systemen is het doel
            van de therapie. Gezondheid moet op alle niveaus verbeterd worden
            anders veroorzaakt de stagnatie op een van die niveaus weer een volgende belasting.
          </Typography>
          <Typography color="textPrimary" paragraph gutterBottom>
            Bewust worden van een eventuele negatieve leefstijl en die om te zetten in
            een positieve leefstijl zorgt voor een complete genezing van een klacht.
          </Typography>
          <Typography variant="h5" color="textPrimary" paragraph>
            Waar kan E.E.N.® Therapie bij helpen?
          </Typography>
          <Typography color="textPrimary" paragraph>
            Depressies, angst, stress, burn-out, vermoeidheid, overgangsklachten
            reuma, artrose, fibromyalgie, pijnen in nek, schouder en rug,obstipatie,
            prikkelbare darm en overige darmklachten.
          </Typography>
        </ContentBox>

        <Grid item xs={1} />
        <Grid item xs={11}>
          <Box paddingTop={8} paddingLeft="8.33%">
            <Typography variant="h4" color="textPrimary" gutterBottom paragraph>
                Geïntegreerde therapievormen
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CollapableBoxList />
        </Grid>
      </Grid>
    </Container>
    </Box>
    <Footer />
  </>
);

export default Therapy;
