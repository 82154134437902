import React from 'react';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import chroma from 'chroma-js';
import NavigationBar from '../genericComponents/NavigationBar';
import ContentBox from '../genericComponents/ContentBox';
import Footer from '../genericComponents/Footer';

import sunTree from '../images/sunTree.jpg';
import FullBackgroundImage from '../FullBackgroundImage';
import { COLORS } from '../constants';

const E_MAIL = 'recordo@kpnmail.nl';

const Contact = () => {
  const handleEmailClick = () => {
    window.open(`mailto:${E_MAIL}`);
  };

  return (
    <>
      <NavigationBar />
      <Box minHeight="calc(100vh - 86px - 119px - 42px)">
        <Container fixed>
          <Grid container spacing={3}>
            <ContentBox
              center={{
                lat: 51.255587,
                lng: 5.729247,
              }}
              zoom={15}
              imageWidth={4}
              direction="right"
            >
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Neem contact op voor een afspraak
              </Typography>
              <Typography color="textPrimary" paragraph>
                Indien je vragen hebt over gezondheidsklachten of je wilt een afspraak maken,
                mail of bel me. Ik mail of bel je zo snel mogelijk terug.
              </Typography>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Gegevens
              </Typography>
              <Typography color="textPrimary">
                Tromplaan 142, 6004 ES, Weert
              </Typography>
              <Typography color="textPrimary" paragraph>
                0495-541157
              </Typography>

              <Button color="default" onClick={handleEmailClick}>
                {E_MAIL}
              </Button>
            </ContentBox>

          </Grid>
        </Container>
        <FullBackgroundImage
          width="100%"
          minHeight="504px"
          src={sunTree}
          display="flex"
          alignItems="center"
          marginTop={12}
          position="relative"
        >
          <Container fixed style={{ zIndex: 2 }}>
            <Box paddingTop={4} paddingBottom={4}>
              <Grid container>
                <Grid item sm={7}>
                  <Typography variant="h4" color="textPrimary" gutterBottom>
                    "Ik herken en ik verander"
                  </Typography>
                  <Typography variant="h5" color="textPrimary">
                    - Recordo
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Box
            height="100%"
            width="100%"
            position="absolute"
            style={{
              backgroundImage: `linear-gradient(
            90deg,
            ${chroma(COLORS.SAND).alpha(0.8)} 0%,
            ${chroma(COLORS.SAND).alpha(0.8)} 40%,
            ${chroma(COLORS.SAND).alpha(0)} 80%,
            ${chroma(COLORS.SAND).alpha(0)} 100%
          )`,
            }}
          />
        </FullBackgroundImage>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
