import React from 'react';
import { Box, Container, styled } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import chroma from 'chroma-js';
import { Link as NavigationLink } from 'react-router-dom';
import NavigationBar from '../genericComponents/NavigationBar';

import branch from '../images/branch.jpg';
import towerStones from '../images/towerStones.jpg';
import ContentBox from '../genericComponents/ContentBox';
import FullBackgroundImage from '../FullBackgroundImage';
import { COLORS } from '../constants';
import Footer from '../genericComponents/Footer';

const StyledNavigationLink = styled(NavigationLink)({
  textDecoration: 'none',
});

const Info = () => (
  <>
    <NavigationBar />
    <Container fixed>
      <Grid container spacing={3}>
        <ContentBox src={branch} imageWidth={3}>
          <Typography variant="h4" color="textPrimary" gutterBottom paragraph>
            Meer informatie
          </Typography>
          <Typography color="textPrimary" paragraph>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
            ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
            montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
            pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
            vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a,
            venenatis vitae, justo. Nullam dictum felis eu
            pede mollis pretium. Integer tincidunt.
          </Typography>
        </ContentBox>
      </Grid>
    </Container>
    <Footer />
  </>
);

export default Info;
