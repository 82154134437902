import { Box, styled } from '@material-ui/core';
import React from 'react';

const FullBackgroundImage = styled(({ src, ...other }) => <Box {...other} />)({
  height: props => props.height || '100%',
  minHeight: 240,
  background: props => `url('${props.src}') no-repeat scroll center center`,
  backgroundSize: props => props.backgroundSize || 'cover',
});

export default FullBackgroundImage;
