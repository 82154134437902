import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import { Box, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import FullBackgroundImage from '../FullBackgroundImage';
import { COLORS } from '../constants';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import ContentBox from "./ContentBox";

const HEIGHT_IMAGE = 304;
const HEIGHT_TEXT = 200;


const SmallContentBox = ({
  src,
  title,
  description,
  dialogContent,
  left = false,
  right = false,
}) => {
  const small = useMediaQuery('(max-width:768px)');
  const radius = small ? 36 : 72;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        width="100%"
        height={HEIGHT_IMAGE}
        borderRadius={`
          ${left ? `${radius}px` : '0'}
          ${right ? `${radius}px` : '0'}
          0 0`
        }
        overflow="hidden"
      >
        <FullBackgroundImage src={src} />
      </Box>
      <Box width="100%" minHeight={HEIGHT_TEXT} padding={5} bgcolor={COLORS.WHITE}>
        <Typography variant="h6" color="textPrimary" paragraph>
          {title}
        </Typography>
        <Typography color="textPrimary">
          {description}
        </Typography>
        <Button color="default" onClick={() => setOpen(true)}>
          Lees meer
        </Button>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContent}
          </DialogContentText>
          <DialogActions/>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SmallContentBox;
