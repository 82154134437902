import React from 'react';
import {
  Box, Container,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import NavigationBar from '../genericComponents/NavigationBar';
import ContentBox from '../genericComponents/ContentBox';
import Footer from '../genericComponents/Footer';

import image from '../images/womanNearWater.jpg';

const Privacy = () => (
  <>
    <NavigationBar />
    <Box minHeight="calc(100vh - 86px - 171px - 42px)">
      <Container fixed>
        <Grid container spacing={3}>
          <ContentBox
            src={image}
            imageWidth={4}
            direction="right"
          >
            <Box>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Privacyverklaring
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                P.J.G. Vaessen/Praktijk Recordo is verantwoordelijk voor de verwerking van
                persoonsgegevens zoals weergegeven in deze privacyverklaring. Verwerking van
                de persoonsgegevens gebeurt op zorgvuldige wijze in overeenstemming met de eisen
                die de Algemene Verordening Gegevensbescherming (AVG) vanaf 25 mei 2018 stelt.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Voor een goede behandeling is het noodzakelijk dat ik als uw behandelende therapeut
                een dossier aanleg. Dit is ook een wettelijke plicht opgelegd door de Wet op de
                geneeskundige behandelingsovereenkomst (WGBO/Wkkgz). Uw dossier bevat
                aantekeningen over uw gezondheidstoestand en gegevens over de uitgevoerde
                onderzoeken en behandelingen.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Ook worden in het dossier gegevens opgenomen die voor uw behandeling noodzakelijk
                zijn en die ik, na uw expliciete toestemming, heb opgevraagd bij een andere
                zorgverlener, bijvoorbeeld bij de huisarts.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Ik doe mijn best om uw privacy te waarborgen. Dit betekent onder meer dat ik:
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                <ul>
                  <li>
                    Zorgvuldig omga met uw persoonlijke en medische gegevens.
                  </li>
                  <li>
                    Ervoor zorg dat onbevoegden geen toegang hebben tot uw gegevens.
                  </li>
                </ul>
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Als uw behandelende therapeut heb ik als enige toegang tot de gegevens in
                uw dossier.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Ik heb een wettelijke geheimhoudingsplicht (medisch beroepsgeheim).
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                De gegevens uit uw dossier kunnen ook nog voor de volgende doelen gebruikt worden:
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                <ul>
                  <li>
                    Om andere zorgverleners te informeren, bijvoorbeeld als de therapie is afgerond
                    of bij een verwijzing naar een andere behandelaar. Dit gebeurt alleen met uw
                    expliciete (schriftelijke) toestemming.
                  </li>
                  <li>
                    Voor het gebruik voor waarneming, tijdens mijn afwezigheid.
                  </li>
                  <li>
                    Voor het geanonimiseerde gebruik tijdens intercollegiale toetsing.
                  </li>
                  <li>
                    Een klein deel van de gegevens uit uw dossier wordt gebruikt voor de financiële
                    administratie, zodat ik een factuur kan opstellen.
                  </li>
                </ul>
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Als ik vanwege een andere reden gebruik wil maken van uw gegevens, dan zal ik
                u eerst informeren en expliciet uw toestemming vragen.
              </Typography>
              <Typography color="textPrimary">
                U heeft te allen tijde recht tot inzage in uw cliëntendossier.
              </Typography>
              <Typography color="textPrimary">
                U heeft te allen tijde het recht uw gegevens te laten verwijderen op schriftelijk
                verzoek.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                De gegevens in uw cliëntendossier blijven, zoals wettelijk vereist (WGBO), 20
                jaar bewaard.
              </Typography>
              <Typography variant="h5" color="textPrimary" gutterBottom paragraph>
                Privacy op de zorgnota
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Op de zorgnota die u ontvangt staan de gegevens die door de zorgverzekeraar
                gevraagd worden, zodat u deze nota kan declareren bij uw zorgverzekeraar.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                <ul>
                  <li>Uw naam, adres en woonplaats.</li>
                  <li>Uw klantnummer en/of uw geboortedatum.</li>
                  <li>Verzekering en/of polisnummer.</li>
                  <li>De datum van de behandeling.</li>
                  <li>Een korte omschrijving van de behandeling.</li>
                  <li>Prestatiecode.</li>
                  <li>De kosten van het consult.</li>
                </ul>
              </Typography>
              <Typography variant="h5" color="textPrimary" gutterBottom paragraph>
                Wijzigingen privacyverklaring
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Deze privacyverklaring is voor het laatst aangepast op 15-07-2023.
                Recordo kan deze privacyverklaring aanpassen. Nieuwe versies worden altijd op de
                website gepubliceerd. Wij raden u daarom aan om deze verklaring geregeld te
                raadplegen, zodat u op de hoogte blijft van wijzigingen.
              </Typography>
            </Box>
          </ContentBox>
        </Grid>
      </Container>
    </Box>
    <Footer gutterTop />
  </>
);

export default Privacy;
