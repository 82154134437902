import React from 'react';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import NavigationBar from '../genericComponents/NavigationBar';

import sunTree from '../images/sunTree.jpg';
import ContentBox from '../genericComponents/ContentBox';
import Footer from '../genericComponents/Footer';

const Home = () => (
  <>
    <NavigationBar />
    <Box minHeight="calc(100vh - 86px - 170px - 42px)">
      <Container fixed>
        <Grid container spacing={3} id="recordo">
          <ContentBox src={sunTree} imageWidth={4}>
            <Typography variant="h4" color="textPrimary" gutterBottom paragraph>
              Recordo staat voor “Ik herken”
            </Typography>
            <Typography color="textPrimary" paragraph>
              Vaak herkennen we de signalen van ons lichaam niet, met als gevolg dat er
              pijnen, verzuringen of verhardingen ontstaan die ons lichaam blokkeren.
              Door deze signalen tijdig te herkennen en te behandelen ontstaat er een betere
              balans in zowel het fysieke, emotionele en mentale leefpatroon.
            </Typography>
            <Typography color="textPrimary" paragraph>
              In mijn praktijk probeer ik naast de behandeling, je een stukje bewuster te maken
              van deze oude beperkende leefstijl in een leefstijl die jouw klachten vermindert
              of geneest.
            </Typography>
            <Typography color="textPrimary" paragraph gutterBottom>
              Herkennen van de oude patronen is daarbij de start van jouw genezing.
            </Typography>
            <Typography variant="h5" color="textPrimary" paragraph>
              “Ik herken en verander”
            </Typography>
          </ContentBox>
        </Grid>
      </Container>
    </Box>
    <Footer gutterTop />
  </>
);

export default Home;
