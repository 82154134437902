import { Box, styled, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React, { useMemo } from 'react';
import chroma from 'chroma-js';
import { Map, Marker } from 'pigeon-maps';
import { COLORS } from '../constants';
import FullBackgroundImage from '../FullBackgroundImage';


const VerticalCenterBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '3rem',
  boxSizing: 'border-box',
});

const MapContainer = styled(Box)({
  display: 'block',
  width: '100%',
  height: '100%',
  minHeight: '120px',
  '& > div': {
    display: 'block !important',
  },
});

const TextBox = ({ width, children }) => (
  <>
    <Grid item xs={12} md={width}>
      <VerticalCenterBox>
        <Box>
          {children}
        </Box>
      </VerticalCenterBox>
    </Grid>
  </>
);

const ImageBox = ({ width, src }) => (
  <Grid item xs={12} md={width}>
    <FullBackgroundImage src={src} />
  </Grid>
);

const MapBox = ({ width, center: { lat, lng }, zoom }) => (
  <Grid item xs={12} md={width}>
    <MapContainer>
      <Map defaultCenter={[lat, lng]} zoom={zoom || 8} attribution={false}>
        <Marker anchor={[lat, lng]} payload={1} />
      </Map>
    </MapContainer>
  </Grid>
);

const LeftOrientedBox = ({
  textWidth, imageWidth, src, center, children, zoom,
}) => (
  <>
    <TextBox
      width={textWidth}
    >
      {children}
    </TextBox>
    {(!center)
      ? (
        <ImageBox
          width={imageWidth}
          src={src}
        />
      ) : (
        <MapBox
          width={imageWidth}
          center={center}
          zoom={zoom}
        />
      )
    }
  </>
);

const RightOrientedBox = ({
  textWidth, imageWidth, src, center, children, zoom,
}) => (
  <>
    {(!center)
      ? (
        <ImageBox
          width={imageWidth}
          src={src}
        />
      ) : (
        <MapBox
          width={imageWidth}
          center={center}
          zoom={zoom}
        />
      )
    }
    <TextBox
      width={textWidth}
    >
      {children}
    </TextBox>
  </>
);

const ContentBox = ({
  src,
  width = 12,
  imageWidth = 6,
  offsetLeft = 0,
  offsetRight = 0,
  direction = 'left',
  children,
  center,
  zoom,
}) => {
  const textWidth = width - imageWidth;
  const innerWidth = width - offsetRight - offsetLeft;
  const contentBoxProperties = {
    textWidth,
    imageWidth,
    src,
    children,
    center,
    zoom,
  };
  const small = useMediaQuery('(max-width:768px)');
  const radius = small ? 36 : 72;
  const InnerBox = useMemo(() => (
    <Box
      bgcolor={COLORS.WHITE}
      marginTop={small ? 3 : 6}
      borderRadius={`${radius}px 0 ${radius}px 0`}
      overflow="hidden"
      style={{
        // ToDo move to constants or styled component
        boxShadow: `0px 0px 32px 0px ${chroma(COLORS.TAUPE).alpha(0.08)}`,
      }}
    >
      <Grid container spacing={2}>
        { direction === 'left' && !small
          ? <LeftOrientedBox {...contentBoxProperties} />
          : <RightOrientedBox {...contentBoxProperties} />
      }
      </Grid>
    </Box>
  ), [small]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={offsetLeft} />
      <Grid item xs={innerWidth}>
        {InnerBox}
      </Grid>
      <Grid item xs={offsetRight} />
    </Grid>
  );
};

export default ContentBox;
