import React from 'react';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import NavigationBar from '../genericComponents/NavigationBar';
import ContentBox from '../genericComponents/ContentBox';
import Footer from '../genericComponents/Footer';
import paul from '../images/paul.jpg';

const About = () => (
  <>
    <NavigationBar />
    <Box minHeight="calc(100vh - 86px - 171px - 42px)">
      <Container fixed>
      <Grid container spacing={3}>
        <ContentBox
          src={paul}
          imageWidth={4}
          direction="right"
        >
          <Box>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              Paul Vaessen (E.E.N.
              <sup>®</sup>
              {' '}
              Therapeut)
            </Typography>
            <Typography color="textPrimary" paragraph>
              Tijdens de behandelingen vanuit de E.E.N.® Therapie leer ik je zien wat bij je past
              en krijgt je leven een bewustere invulling. Dat is voor mij ware heling zowel of
              fysiek, emotioneel als mentaal niveau. Heel worden in al je delen in al je
              mogelijkheden. Een genezingsproces van jouw individueel bewustzijn. Hoe meer dit
              groeit hoe meer je geneest, heel wordt.
            </Typography>
            <Typography variant="h5" color="textPrimary" gutterBottom>
              Mijn Visie
            </Typography>
            <Typography color="textPrimary" gutterBottom paragraph>
              Hoe meer je herinnert van jezelf des te meer je wordt wie je bent.
            </Typography>
            <Box marginTop={5}>
              <Typography color="textPrimary" variant="h5">
                Recordo – "Ik herken"
              </Typography>
            </Box>
          </Box>
        </ContentBox>
      </Grid>
    </Container>
    </Box>
    <Footer gutterTop />
  </>
);

export default About;
