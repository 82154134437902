import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse/Collapse';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button/Button';
import Box from '@material-ui/core/Box/Box';
import chroma from 'chroma-js';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';
import SmallContentBox from './SmallContentBox';
import { COLORS } from '../constants';

import beach from '../images/beach.jpg';
import pebbles from '../images/pebbles.jpg';

const CollapableBoxList = ({
  content = [{
    title: 'Essential Counseling',
    description: 'Essential counseling is voornamelijk bedoeld voor mentale en emotionele problemen, zoals'
      + 'stress, burn-out, angsten, verwerking van verdriet.',
    dialogContent:
  <>
    <Typography paragraph>
      Essential counseling is voornamelijk bedoeld voor mentale en emotionele problemen, zoals
      stress, burn-out, angsten, verwerking van verdriet.
    </Typography>
    <Typography variant="h6" paragraph>Met als doel:</Typography>
    <div>
      <ul>
        <li>
          <Typography paragraph>
            Inzicht te krijgen in je innerlijke en naar buiten gerichte processen.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Acceptatie van je oude structuren en de nieuwe weg te zien.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Transformatie naar een andere levensstijl met een eventuele nieuwe persoonlijkheid.
          </Typography>
        </li>
      </ul>
    </div>
    <Typography>
      Als counselor ga ik uit van het zelfgenezend vermogen van de cliënt.
      Let wel: essential counseling is niet bedoeld om je prestaties in het bedrijfsleven of je
      privéleven te versterken, maar om een bestaand ziektepatroon te doorbreken en te veranderen.
    </Typography>
  </>,
    src: beach,
  }, {
    title: 'Triggerpoint therapie',
    description: 'Door foute bewegingen of houdingen vanuit het werk of dagelijkse bezigheden'
      + 'ontstaan er vaak verhardingen of verzuringen in de spieren en gewrichten.',
    dialogContent:
  <>
    <Typography paragraph>
      Door foute bewegingen of houdingen vanuit het werk of dagelijkse bezigheden
      ontstaan er vaak verhardingen of verzuringen in de spieren en gewrichten.
    </Typography>
    <Typography>
      Door deze punten op de spieren met de juiste vingerdruk te behandelen komen de
      afvalstoffen vrij en kan het lichaam weer op een betere manier functioneren zonder
      of met minder pijn of bewegingsbeperkingen. Je levenskwaliteit neemt weer toe.
    </Typography>
  </>,
    src: pebbles,
  }],
}) => {
  // ToDo move height of blocks to constants
  const collapseHeightOffset = 210;
  const overlayButtonHeight = 210;
  const collapsedHeight = collapseHeightOffset + 504 + 8 * 3;
  const transition = {
    transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  };

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const mappedContent = (
    <Grid container spacing={3}>
      {content.map((item, index) => (
        <Grid item xs={12} sm={6} md={6}>
          <SmallContentBox
            {...item}
            left={index === 0}
            right={(index + 1) / 2 === 1}
          />
          <Box height={open ? 54 + 16 : 0} />
        </Grid>
      ))}
    </Grid>
  );

  console.log('content length is', content.length);

  return (
    <Box position="relative">
      {content.length > 3
        ? (
          <>
            <Collapse
              in={open}
              collapsedHeight={`${collapsedHeight}px`}
            >
              {mappedContent}
            </Collapse>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              width="100%"
              height={open ? 54 : overlayButtonHeight}
              bottom={0}
              style={{ ...transition }}
            >
              <Box zIndex={2}>
                <Button onClick={toggleOpen}>
                  {open
                    ? 'Bekijk minder'
                    : 'Bekijk meer'
            }
                </Button>
              </Box>
              <Box
                position="absolute"
                width="100%"
                height="100%"
                zIndex={1}
                style={{
                  ...transition,
                  opacity: !open ? 1 : 0,
                  backgroundImage: `linear-gradient(
              ${chroma(COLORS.SAND).alpha(0)} 0%,
              ${chroma(COLORS.SAND).alpha(!open ? 0.8 : 0)} 60%,
              ${chroma(COLORS.SAND).alpha(!open ? 1 : 0)} 100%
            )`,
                }}
              />
            </Box>
          </>
        )
        : <Box marginBottom={8}>{mappedContent}</Box>}
    </Box>
  );
};

export default CollapableBoxList;
