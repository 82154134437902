import React from 'react';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Link from '@material-ui/core/Link';
import NavigationBar from '../genericComponents/NavigationBar';
import ContentBox from '../genericComponents/ContentBox';
import Footer from '../genericComponents/Footer';

import image from '../images/poppy.jpg';

const Miscellaneous = () => (
  <>
    <NavigationBar />
    <Box minHeight="calc(100vh - 86px - 171px - 42px)">
      <Container fixed>
        <Grid container spacing={3}>
          <ContentBox
            src={image}
            imageWidth={4}
            direction="right"
          >
            <Box>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Toegankelijkheid praktijk
              </Typography>
              <Typography color="textPrimary" paragraph>
                De praktijk bevindt zich op de 1e etage en is derhalve niet toegankelijk
                voor rolstoelhouders of cliënten die slecht ter been zijn.
              </Typography>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Klachtenregeling
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Wanneer nu niet tevreden bent over de behandeling.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Bij een klacht over een behandeling wordt eerst getracht dit met u te bespreken.
                Als we er samen niet uitkomen kunt u contact opnemen met de onafhankelijke
                klachtenfunctionaris van Quasir via email (bemiddeling@quasir.nl).
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Deze kan ondersteuning bieden bij formulering van de klacht of bij onvrede en helpt
                bij het zoeken naar oplossingen. De therapeut heeft het recht om hiervan op de
                hoogte te worden gesteld. De praktijk is hiervoor aangesloten bij Quasir,
                coördinatie klachtenbemiddeling.
              </Typography>
              <Typography color="textPrimary" gutterBottom paragraph>
                Voor meer informatie kijk op de website van
                {' '}
                <Link href="https://vbag.nl/consument/klachten-en-geschillen/">
                  mijn beroepsvereniging: VBAG
                </Link>
                .
              </Typography>
            </Box>
          </ContentBox>
        </Grid>
      </Container>
    </Box>
    <Footer gutterTop />
  </>
);

export default Miscellaneous;
