import { Box, Container, styled } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { COLORS } from '../constants';
import rcbzLogo from '../rbczLogo.svg';
import vbagLogo from '../vbagLogo.png';

const StyledPartnerImage = styled('img')({
  maxWidth: '100%',
  maxHeight: 48,
});

const StyledPartnerImageContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

const Footer = (props) => {
  // ToDo add prop validation
  const { gutterTop } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor={COLORS.ASH_WHITE}
      marginTop={gutterTop ? 8 : false}
      paddingTop={3}
      paddingBottom={3}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }} color="textPrimary">Recordo</Typography>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Praktijk voor natuurgeneeskunde
              <br />
              0495-541157
            </Typography>
            <Typography variant="body2" color="textPrimary">
              KvK: 14127100 · Btw-id: NL001481063B52
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item sm={1} />
          </Hidden>
          <Grid item xs={5} sm={2}>
            <StyledPartnerImageContainer>
              <StyledPartnerImage src={rcbzLogo} alt="logo" />
            </StyledPartnerImageContainer>
          </Grid>
          <Grid item xs={6} sm={3}>
            <StyledPartnerImageContainer>
              <StyledPartnerImage src={vbagLogo} className="partnerLogo" alt="logo" />
            </StyledPartnerImageContainer>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
