import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import AppBar from '@material-ui/core/AppBar/AppBar';
import {
  Box, styled, Drawer, Typography, Container, Link, IconButton,
} from '@material-ui/core';
import chroma from 'chroma-js';
import { Link as NavigationLink } from 'react-router-dom';
import { Menu } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { COLORS } from '../constants';

const MENU_TITLE_PREFIX = '';

const MENU_ITEMS = [{
  title: 'Home',
  url: `${MENU_TITLE_PREFIX}`,
}, {
  title: 'Therapieën',
  url: `${MENU_TITLE_PREFIX}/therapie`,
}, {
  title: 'Tarief',
  url: `${MENU_TITLE_PREFIX}/tarief`,
}, {
  title: 'Over mij',
  url: `${MENU_TITLE_PREFIX}/over-recordo`,
}, {
  title: 'Overig',
  url: `${MENU_TITLE_PREFIX}/overig`,
}, {
  title: 'Privacy',
  url: `${MENU_TITLE_PREFIX}/privacy`,
}, {
  title: 'Contact',
  url: `${MENU_TITLE_PREFIX}/contact`,
}];

const AppBarWithShadow = styled(AppBar)({
  boxShadow: `0px 8px 10px -5px ${chroma(COLORS.TAUPE).alpha(0.06)},`
    + `0px 6px 30px 5px ${chroma(COLORS.TAUPE).alpha(0.05)}`,
});

const AppBarContainer = styled(Box)({
  paddingTop: 16,
  paddingBottom: 16,
  background: COLORS.WHITE,
});

const Logo = styled(Typography)({
  fontWeight: 'bold',
});

const StyledNavigationLink = styled(NavigationLink)({
  textDecoration: 'none',
});

const NavigationBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  return (
    <>
      <AppBarWithShadow position="sticky" elevation={0}>
        <AppBarContainer>
          <Container maxWidth="lg">
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={1} />
              <Hidden mdUp>
                <Grid item sm={0}>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleDrawer}
                  >
                    <Menu />
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item sm={3}>
                <StyledNavigationLink to="/">
                  <Logo variant="h4" color="textPrimary">
                  Recordo
                  </Logo>
                </StyledNavigationLink>
              </Grid>
              <Hidden smDown>
                {MENU_ITEMS.slice(1).map(({ title, url }) => (
                  <Grid item sm={1.5}>
                    <StyledNavigationLink to={url}>
                      <Link>
                        <Typography color="textPrimary">{title}</Typography>
                      </Link>
                    </StyledNavigationLink>
                  </Grid>
                ))}
              </Hidden>
            </Grid>
          </Container>
        </AppBarContainer>
      </AppBarWithShadow>
      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <div
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
          role="presentation"
        >
          <List>
            {MENU_ITEMS.map(({ title, url }) => (
              <ListItem button key={title}>
                <StyledNavigationLink to={url}>
                  <Link>
                    <ListItemText primary={title} />
                  </Link>
                </StyledNavigationLink>
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default NavigationBar;
