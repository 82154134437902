import {createMuiTheme, responsiveFontSizes} from '@material-ui/core';

const MAP_KEY = 'AIzaSyD7IlHeYpvSi2CtcpDrgYS4eBWXiaWd40I';

const COLORS = {
  WHITE: '#fff',
  ASH_WHITE: '#f0ebe6',
  SAND: '#FAF7F3',
  TAUPE: '#736357',
};

const THEME = responsiveFontSizes(createMuiTheme({
  typography: {
    fontFamily: ['CoreRhino'],
    h4: {
      lineHeight: 1.66,
    },
  },
  palette: {
    primary: {
      main: COLORS.TAUPE,
    },
    text: {
      primary: COLORS.TAUPE,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        marginLeft: '-6px',
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '1.5rem',
      },
    },
  },
}), {
  factor: 2.125,
});

export {
  MAP_KEY,
  THEME,
  COLORS,
};
