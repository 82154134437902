import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Box, styled } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import Therapy from './Therapy';
import { COLORS, THEME } from './constants';
import About from './About';
import Reimburstment from './Reimburstment';
import Contact from './Contact';
import ScrollToTop from './genericComponents/ScrollToTop';
import Info from './Info';
import Privacy from './Privacy';
import Miscellaneous from './Miscellaneous';

const BackgroundColorBox = styled(Box)({
  backgroundColor: COLORS.SAND,
  minHeight: '100vh',
});

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={THEME}>
        <BackgroundColorBox>
          <Router
            onUpdate
          >
            <ScrollToTop />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/info" exact component={Info} />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="/therapie" exact component={Therapy} />
              <Route path="/over-recordo" exact component={About} />
              <Route path="/tarief" exact component={Reimburstment} />
              <Route path="/overig" exact component={Miscellaneous} />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="/contact" exact component={Contact} />
            </Switch>
          </Router>
        </BackgroundColorBox>
      </ThemeProvider>
    </>
  );
}

export default App;
